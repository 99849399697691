<template>
  <div>
    <b-modal
      :no-close-on-backdrop="false"
      size="xm"
      :visible="isShowCategoryModal"
      :title="title"
      cancel-title="Close"
      centered
      ok-title="Accept"
      cancel-variant="outline-secondary"
      :hide-footer="true"
      @hide="hideMethod"
      @change="(val) => $emit('update:is-show-category-modal', val)"
    >
      <b-overlay
        :show="loading"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <validation-observer ref="addProductValidation">
          <!-- form -->
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group :label="$t('lbl_name')" class="mb-2 required">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('lbl_name')"
                    rules="required"
                  >
                    <b-form-input v-model="form.name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col cols="12" v-if="type === TYPE_CATE.category">
                <b-form-group label="Thứ tự hiển thị" class="mb-2 required">
                  <validation-provider
                    #default="{ errors }"
                    name="Thứ tự hiển thị"
                    rules="required"
                  >
                    <b-form-input type="number" v-model="form.priority" @input="handleCheckDuplicate"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                   <span
            v-if="showMessageError"
            class="text-danger"
            style="font-size: 12px; margin-top: 8px"
          >Số thứ tự này đã tồn tại, vui lòng nhập số thứ tự khác</span>
                </b-form-group>
              </b-col>-->
            </b-row>
          </b-form>
        </validation-observer>

        <div class="d-flex mt-2">
          <div class="ml-auto">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="isDisableBtn"
              @click="handleSubmit"
              >Thêm</b-button
            >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="ml-2"
              @click="$emit('update:is-show-category-modal', false)"
              >Hủy</b-button
            >
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BModal,
  VBModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BLink,
  BEmbed,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import { required } from "@validations";
import { TYPE_CATE } from "@/utils/constant";
import { ref, computed } from "@vue/composition-api";

export default {
  components: {
    BModal,
    BButton,
    BOverlay,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const toast = useToast();
    const showMessageError = ref(false);
    const isDisableBtn = computed(() => {
      return showMessageError.value;
    });
    const handleCheckDuplicate = async (order) => {
      try {
        return await store
          .dispatch("configProduct/checkPriority", {
            priority: order,
            supplier_id: store.state.authenticate.userLogin.supplierInfo.id,
          })
          .then((response) => {
            if (order === 0 || order === "") showMessageError.value = false;
            else if (response?.data?.code === 200053)
              showMessageError.value = false;
            else {
              showMessageError.value = true;
            }
          });
      } catch (error) {
        console.log(error);
        showMessageError.value = true;
        return false;
      }
    };
    return {
      toast,
      TYPE_CATE,
      handleCheckDuplicate,
      showMessageError,
      isDisableBtn,
    };
  },
  watch: {
    type(value) {
      switch (value) {
        case TYPE_CATE.category:
          this.title = this.$t("lbl_product_category");
          // Danh mục
          break;
        case TYPE_CATE.dosage:
          this.title = this.$t("lbl_dosage_form");
          // Dạng bào chế
          break;
        case TYPE_CATE.packaging:
          this.title = this.$t("lbl_packaging");
          // Quy cách đóng gói
          break;
        case TYPE_CATE.unit:
          this.title = this.$t("lbl_unit");
          // Đơn vị lưu nhỏ nhất
          break;
        case TYPE_CATE.manufacture:
          this.title = this.$t("lbl_manufacture");
          // Nhà sản xuất
          break;
        case TYPE_CATE.brand:
          this.title = this.$t("lbl_brand");
          // Thương hiệu
          break;
        case TYPE_CATE.origin:
          this.title = this.$t("lbl_origin");
          // Nguồn gốc
          break;
        default:
      }
    },
  },
  props: {
    isShowCategoryModal: {
      type: Boolean,
      required: true,
    },
    type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      form: {
        name: "",
        priority: null,
      },
      required,
      title: "",
      loading: false,
    };
  },
  methods: {
    hideMethod() {
      this.$emit("update:is-show-category-modal", false);
    },
    handleSubmit() {
      this.$refs.addProductValidation.validate().then((success) => {
        if (!success) {
          return;
        }
        if (success) {
          var router = "";
          this.loading = true;
          switch (this.type) {
            case TYPE_CATE.category:
              router = "createCategory";
              // Danh mục
              break;
            case TYPE_CATE.dosage:
              router = "createDosageForm";
              // Dạng bào chế
              break;
            case TYPE_CATE.packaging:
              router = "createSpecification";
              // Quy cách đóng gói
              break;
            case TYPE_CATE.unit:
              router = "createUnit";
              // Đơn vị lưu nhỏ nhất
              break;
            case TYPE_CATE.manufacture:
              router = "createManufacturer";
              // Nhà sản xuất
              break;
            case TYPE_CATE.brand:
              router = "createBrand";
              // Thương hiệu
              break;
            case TYPE_CATE.origin:
              router = "createOrigin";
              // Nguồn gốc
              break;
            default:
          }
          store
            .dispatch(`configProduct/${router}`, {
              ...this.form,
              name: this.form.name,
              priority: this.form.priority
                ? parseInt(this.form.priority)
                : null,
              supplier_id: store.state.authenticate.userLogin.supplierInfo.id,
              view: 2,
            })
            .then((res) => {
              console.log(res);
              this.loading = false;
              this.$emit("commitData", res);
              this.$emit("update:is-show-category-modal", false);
            })
            .catch((e) => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: e.response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.rotated {
  transform: rotate(-90deg);
  /* Equal to rotateZ(45deg) */
}
</style>
